.button {
    font-size: x-large;
    background-color: #0A0A0A;
    color: #FFC627;
    border: 1px solid #FFC627;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 4px;
}

.button:hover {
    background-color: #FFC627;
    color: #0A0A0A;
    border-color: #0A0A0A;
    cursor: pointer;
}

.linkPopup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 20vh;
    width: 40vw;
    top: 40vh;
    left: 30vw;
    z-index: 101;
    background-color: #0A0A0A;
    border: 1px solid #FFC627;
    color: white;
    border-radius: 8px;
    padding: 20px;
}

.creationForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    width: 20vw;
    top: 20vh;
    left: 40vw;
    z-index: 101;
    background-color: #0A0A0A;
    border: 1px solid #FFC627;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
}

.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}

.input {
    width: 95%;
    padding: 5px;
    font-size: x-large;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 5px;
}

.link {
    color: #F0A202;
    text-decoration: none;
}
.link:hover {
    text-decoration: underline;
}