@import './Buttons';

.search-input {
  background-color: #edf0f0;
  border: none;
  border-bottom: 2px solid #477998;
  width: 400px;
  font-size: 1rem;
  margin: 0 20px 0 0;
  padding: 7px
}
.search-input:focus {
  outline: none;
  border-bottom: 2px solid #F0A202;
}

.search-form {
  margin: 55px 0 55px 0;
}