@import '../Variables';

.button {
  background-color: $button-background-color;
  color: $button-primary-color;
  border: 2px solid $button-primary-color;
  border-radius: 5px;
  font-size: 1rem;
  padding: 7px;
  font-family: $font-stack;
  font-weight: bolder;
}
.button:hover {
  background-color: $button-primary-color;
  color: $button-background-color;
  cursor: pointer;
}
.hide-button {
  display: none;
}

.productGroupButton,
.productsButton,
.orderFormButton,
.paginator-container {
  margin-bottom: 25px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.paginator-container {
  margin-right: 25px;
}
.move-center {
  width: 225px;
  margin-left: auto;
  margin-right: auto;
}
.orderFormButton {
  margin-left: 25px;
}