$font-stack: Work Sans, sans-serif;
$background: #edf0f0;

// buttons
$button-background-color: whitesmoke;
$button-primary-color: #477998;

// card
$card-background-color: whitesmoke;
$card-primary-color: #0A2342;
$primary-color: #477998;

// login
$form-background: whitesmoke;