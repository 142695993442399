.select {
  margin: 5px 10px;
  padding: 5px;
}

.select-label {
  font-size: 1rem;
  font-weight: bolder;
}

#options {
  font-size: 1rem;
  margin-top: 5px;
}
.option {
  font-size: 1rem;
}