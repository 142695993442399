
.buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button {
    background-color: #edf0f0;
    border-radius: 4px;
    border: 2px solid #477998;
    color: #477998;
    font-weight: bolder;
    font-family: Work Sans, sans-serif;
    margin: 10px;
    padding: 7px;
    font-size: large;
}

.button:hover {
    color: #edf0f0;
    background-color: #477998;
    cursor: pointer;
}