.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.form {
    width: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    overflow-y: auto;
    max-height: calc(100vh - 100px);
    z-index: 9999;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button {
    background-color: #edf0f0;
    border-radius: 4px;
    border: 2px solid #477998;
    color: #477998;
    font-weight: bolder;
    font-family: Work Sans, sans-serif;
    margin: 10px;
    padding: 7px;
    font-size: large;
}

.button:hover {
    color: #edf0f0;
    background-color: #477998;
    cursor: pointer;
}

.input {
    font-family: Work Sans, sans-serif;
    font-size: large;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #0A2342;
    background: whitesmoke;
    box-sizing: border-box;
    width: 100%;
    margin: 8px 0;
}

.input:focus {
    outline: none;
    border-bottom: 1px solid #F0A202;
}

.paste {
    font-family: Work Sans, sans-serif;
    font-size: small;
    margin: 0;
    width: 372px;
    height: 203px;
}

.uploadButton {
    padding: 5%;
}

.chooseFile {
    background-color: skyblue;
    font-size: medium;
    border: 2px solid black;
    margin: 5%;
}

.fileChosen {
    padding: 5%;
    text-align: center;
}

input[type="file" i ] {
    display: none;
}

.pasteInstructions {
    font-size: smaller;
}

.pasteExample {
    font-size: medium;
    font-weight: bold;
}

.templateLink {
    text-align: center;
    font-size: x-small;
}

.csvUpload {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.textarea {
    min-height: 100px;
}
.errorInput {
    border-color: red;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
}

.error {
    width: 100%;
    background-color: red;
    color: white;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.formError {
    background-color: red;
    color: white;
    padding: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 2px 0;
}

#grayedOut {
    background: gray;
    border: 1px solid gray;
}