@import './Buttons';

.react-daterange-picker__wrapper {
  margin: 10px 0 10px 0;
  border-radius: 5px;
  padding: 3px;
}

.orderforms-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 115%;
}

.date-container {
  text-align: center;
}
#date-checkbox {
  transform: scale(1.4);
  margin-left: 10px;
}

.orderFormButton {
  vertical-align: middle;
  margin: 0;
}

.issuers-and-dates-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 10em;
}
.issuer-container {
  text-align: center;
}

#issuer-options {
  margin: 5px 0 15px 0;
}

.filter-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 200px;
}