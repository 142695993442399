.root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(47, 50, 58);
    color: lightslategray;
    min-height: calc(100vh - 30px);
    box-sizing: border-box;
    width: 150px;
    transition: 0.7s;
    z-index: 2;
    box-shadow: 0 3px 14px 0 rgb(71, 121, 152), 0 3px 6px 0 rgba(0,0,0, .12);
}

.hideRoot {
    width: 0;
    transition: 0.7s;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    width: 150px;
}

.link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    padding: 5px 10px;
    color: inherit;
    text-decoration: none;
    margin: 2px 0;
    width: 100%;
    box-sizing: border-box;
    font-weight: bolder;
}

.link:hover, .link:focus {
    background-color: #115d7d;
    color: whitesmoke;
}

.active {
    background-color: #115d7d;
    color: whitesmoke;
}

