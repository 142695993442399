@import './Buttons';

.sideMenu-button {
  margin: 10px 0 0 0;
  position: relative;
}

.sideMenu-button:focus {
  display: none;
}

.left-arrow-container {
  display: inline-block;
  margin: 3px 10px 0 0;
}

.right-arrow-container {
  display: inline-block;
  margin: 3px 0 0 10px;
}

.header-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 400px;
  margin-top: 10px;
}

.header-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: #edf0f0;
  color: rgb(47, 50, 58);
  box-sizing: border-box;
  height: 50px;
  z-index: 0;
}