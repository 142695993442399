.backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
}

.popup {
    z-index: 51;
    width: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -160%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    border: 2px solid #477998;
    border-radius: 8px;
    padding: 20px;
}

.message {
    font-size: larger;
    color: #477998;
    text-align: center;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button {
    background-color: whitesmoke;
    border: 1px solid #477998;
    border-radius: 4px;
    color: #477998;
    font-size: 1rem;
    margin: 0 10px 0 10px;
    padding: 9px;
}

.button:hover {
    background-color: #477998;
    color: whitesmoke;
    cursor: pointer;
}