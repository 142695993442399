@import './Buttons';

.transactions-menu {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
}
.more-space {
  margin-top: 45px;
}

.transactions-paginator {
  margin-right: 25px;
}

.select-payment-state, .select-product-name {
  display: flex;
  flex-direction: column;
}
.select-payment-state {
  margin-left: 25px;
}
.select-product-name {
  margin: 0 25px 0 25px;
}

select {
  font-size: 1rem;
}
label {
  text-align: center;
}

.hide {
  display: none;
}

.refund-transaction-btn {
  margin: 0 25px 0 25px;
}

.notification-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: whitesmoke;
  color: #477998;
  font-weight: bolder;
  width: 300px;
  padding: 25px;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.select-dates {
  text-align: center;
}
#date-checkbox {
  transform: scale(1.4);
  margin-left: 10px;
}
.react-daterange-picker__wrapper {
  border-radius: 5px;
  margin-top: 10px;
  padding: 3px;
}