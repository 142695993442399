.extraWidth {
    width: 95vw;
    transition: width 0.7s;
}

.extraPadding {
    padding: 10px;
    text-align: center;
    border: 1px solid #477998;
    border-radius: 4px;
    transition: padding 0.7s;
}