@import './Buttons';
@import '../Variables';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $background;
  width: 100vw;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $form-background;
  border-radius: 8px;
  border: 1px solid $form-background;
  padding: 30px;
}

.input {
  font-size: 1rem;
  border-radius: 0;
  margin: 15px;
  font-family: $font-stack;
  color: #477998;
  border: none;
  border-bottom: 2px solid #477998;
  background-color: $form-background;
  font-weight: bolder;
}
.input::placeholder {
  color: #477998;
}
.input:focus {
  outline: none;
  border-bottom: 2px solid #F0A202;
}

.login-header {
  text-align: center;
  color: #0A2342;
}

.login-button {
  margin-top: 20px;
}