.pages {
    display: flex;
    padding: 0;
    flex-direction: row;
}

.page {
    list-style: none;
    color: #477998;
    border: 2px solid #477998;
    padding: 5px;
    background-color: whitesmoke;
    border-radius: 4px;
    margin: 5px;
    font-weight: bolder;
}

.page:hover {
    cursor: pointer;
    background-color: #477998;
    color: whitesmoke;
}

.activePage {
    list-style: none;
    background-color: #477998;
    border-radius: 4px;
    padding: 7px;
    color: whitesmoke;
    margin: 2px;
}

.searchForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.searchField {
    font-size: x-large;
    border-radius: 4px;
    margin: 5px;
    padding: 5px;
}

.searchButton {
    border-radius: 4px;
    background-color: #0A0A0A;
    color: #FFC627;
    padding: 5px;
    margin: 5px;
    font-size: x-large;
}

.filterItem {
    display: inline-block;
    width: 100%;
    border: 1px solid black;
    padding: 5px;
    background: white;
    border-radius: 10px;
}

.exitFilter {
    border-radius: 40px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    border: 1px solid black;
}

.exitIcon {
    color: black;
    font-weight: bolder;
    margin-left: -2px;
}

.userPaginator {
    margin-right: 25px;
}

.dropdownMenu {
    margin-left: 25px;
}

.addButton {
    background-color: whitesmoke;
    color: #477998;
    border: 2px solid #477998;
    border-radius: 5px;
    font-size: 1rem;
    padding: 7px;
    font-family: Work Sans, sans-serif;
    font-weight: bolder;
    margin-left: 25px;
}

.addButton:hover, .okayButton:hover {
    background-color: #477998;
    color: whitesmoke;
    cursor: pointer;
}

.okayButton {
    background-color: whitesmoke;
    color: #477998;
    border: 2px solid #477998;
    border-radius: 5px;
    font-size: 1rem;
    padding: 7px;
    font-family: Work Sans, sans-serif;
    font-weight: bolder;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.form {
    width: 350px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    border: 1px solid whitesmoke;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    overflow-y: auto;
    height: 260px;
}