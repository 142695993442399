.table {
    color: #0A2342;
    width: 100%;
    transition: width 0.7s;
    box-shadow: 0 3px 14px 0 rgb(71, 121, 152), 0 3px 6px 0 rgba(0,0,0, .12);
}

.underlinedRow td {
    border-bottom: 1px solid #2F323A;
}

.cell {
    text-align: center;
    border: 1px solid #477998;
    border-radius: 4px;
    transition: padding 0.7s;
}

.headerCell {
    background-color: white;
    color: black;
    border-radius: 4px;
}

.row:hover {
    background-color: #477998;
    color: white;
    cursor: pointer;
}

.root {
    width: 97%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px;
}

.buttonRow {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
}

.addButton {
    background-color: whitesmoke;
    color: #F0A202;
    font-size: x-large;
    border: none;
}

.addButton:hover {
    cursor: pointer;
}

.actionsButton {
    /*position: relative;*/
    display: inline-block;
    margin: 0 15px 0 15px;
}

.dropdown {
    display: none;
    position: absolute;
    background-color: whitesmoke;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
}

.actionsButton:hover .dropdown {
    display: block;
}

.dropdownButton {
    background-color: #edf0f0;
    font-size: large;
    color: #477998;
    border: 2px solid #477998;
    padding: 3px;
    margin: 3px;
    border-radius: 4px;
    font-family: Work Sans, sans-serif;
    font-weight: bolder;
}

.dropdownButton:hover {
    background-color: #477998;
    color: #edf0f0;
}

td {
    font-size: 1.1rem;
    padding: 5px;
}