@import '../Variables';

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
}

.card {
  flex-basis: 30%;
  flex-grow: 1;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  padding: 15px;
  margin: 15px;
  box-sizing: border-box;
  background: $card-background-color;
  color: $card-primary-color;
  transition: .5s;
  text-decoration: none;
  //transform: scale(0.1);
}

.card:hover {
  cursor: pointer;
  transform: translate(-5px, -5px);
  box-shadow: 5px 5px 8px lightslategrey;
}

.user-name {
  margin-right: 25px;
}

.user-name, .user-status {
  display: inline;
}

.user-status {
  font-weight: bolder;
}

.card-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-name {
  max-width: 150px;
  margin-right: 25px;
}

.collapse-btn-container {
  text-align: center;
  padding: 7px 0 25px 0;
  width: 100%;
}