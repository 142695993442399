.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #edf0f0;
    width: 100%;
}

.window {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: #edf0f0;
    min-height: calc(100vh - 30px);
}

.App {
    display: flex;
    flex-direction: row;
    font-family: Work Sans, Gotham, sans-serif;
}