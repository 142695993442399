.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
    padding: 5px;
    box-sizing: border-box;
    font-weight: bolder;
}
.badge:hover {
    cursor: pointer;
    text-decoration: underline;
}

.options {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: 10px;
    justify-content: center;
    align-items: center;
    z-index: 150;
    background-color: #edf0f0;
    border: 1px solid #477998;
    color: #477998;
    width: 150px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    width: 100vw;
    height: 100vh;
}

.option {
    color: #477998;
    background-color: #edf0f0;
    border: 1px solid #477998;
    border-radius: 4px;
    font-family: Work Sans, sans-serif;
    font-weight: bold;
    padding: 3px;
    margin: 3px;
}

.option:hover {
    color: #edf0f0;
    background-color: #477998;
    cursor: pointer;
}